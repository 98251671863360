import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful.jsx";
import MetaTags from "react-meta-tags";
import Hero from "../../../components/impacto/Hero.js";
import Navegation from "../../../components/impacto/Navegation.js";
import Buttonarrow from "../../../components/Botonarrow.js";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "../../../styles/global.css";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";
import BoldContentful from "../../../utils/BoldContentful.jsx";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Divsearch = styled.div`
  width: 400px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Text = styled.div`
  width: 100%;
`;
const Cardcontent = styled.div`
  width: 90%;
  max-width: 1440px;
  display: grid;
  //grid-row-start: 1fr 1fr ;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  row-gap: 50px;
  justify-content: center;
  position: relative;
  z-index: 1;
  justify-items: center;

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 25px;
    column-gap: 16px;
    padding: 0px 30px;
  }
`;

const Cardcontentcol = styled.div`
  width: 100%;
  max-width: 1440px;
  display: grid;
  //grid-row-start: 1fr 1fr ;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  row-gap: 50px;
  justify-content: center;
  position: relative;
  z-index: 1;
  justify-items: center;

  .head {
    grid-area: "hd";
  }

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 25px;
    column-gap: 16px;
    padding: 0px 30px;
  }
`;

const Cardcontainer = styled.div`
  position: relative;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
    padding: 16px;
  }
`;

const Foto = styled.div`
  height: 350px;
  width: 100%;
  background-image: url(${(p) => p.fotourl});
  border-radius: 4px;
  background-size: cover;

  :hover {
    box-shadow: 0px 0px 40px rgba(83, 202, 236, 0.7);
  }
`;

const Cardcontainercol = styled.div`
  position: relative;
  width: 384px;
  height: 465px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Fotocol = styled.div`
  height: 46%;
  width: 100%;
  background-image: url(${(p) => p.fotourl});
  border-radius: 4px;
  background-size: cover;

  :hover {
    box-shadow: 0px 0px 40px rgba(83, 202, 236, 0.7);
  }
`;

const Texto = styled.div`
  height: 54%;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 800;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  p {
    color: var(--gris);
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const Textoauth = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 800;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  p {
    color: var(--gris);
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
  }
`;

const Authorcontent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Articlesdiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonStyled = styled.div`
  position: absolute;
  z-index: 1;
  right: 25px;
  top: 310px;
`;

const Longbio = styled.div`
  p {
    margin-bottom: 8px;
  }
`;

const ButtonStyledmed = styled.a`
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 224px;
`;

const Buttoncol = styled(Link)`
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 224px;
`;

const Linklinkedin = styled.a``;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const Cardarticle = (props) => {
  // const [slug , setSlug] = useState("");

  let slug = "";
  if (props?.url.includes("(")) {
    const newurl = props?.url.replace(/ *\([^)]*\) */g, "");
    slug = newurl
      .replaceAll(" ", "--")
      .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  } else {
    slug = props?.url
      .replaceAll(" ", "--")
      .normalize("NFD")
      .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  //let slug = props?.url.replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

  return (
    <Cardcontainercol>
      <Fotocol fotourl={props.imagen}></Fotocol>
      <Texto>
        <h2>{props.titulo}</h2>

        <p>{props.content}</p>
      </Texto>
      <Buttoncol to={`/impacto/columna/${slug}`}>
        {" "}
        <Buttonarrow></Buttonarrow>
      </Buttoncol>
    </Cardcontainercol>
  );
};

const Cardfilter = (props) => {
  return (
    <div style={{ transform: "translateY(-44px)" }}>
      <Link
        to={`/impacto/autores`}
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "var(--morado)",
          marginBottom: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.24388 17.3144C7.93215 17.5872 7.45833 17.5556 7.18557 17.2439L0.185568 9.24387C-0.0618553 8.9611 -0.0618553 8.53888 0.185568 8.25611L7.18557 0.256111C7.45833 -0.0556164 7.93215 -0.087204 8.24388 0.185556C8.55561 0.458319 8.58719 0.93214 8.31443 1.24387L2.40283 7.99999L16.75 7.99999C17.1642 7.99999 17.5 8.33578 17.5 8.74999C17.5 9.1642 17.1642 9.49999 16.75 9.49999L2.40283 9.49999L8.31443 16.2561C8.58719 16.5678 8.55561 17.0417 8.24388 17.3144Z"
            fill="#8A65C4"
          />
        </svg>
        Regresar
      </Link>
      <Cardcontainer>
        <Foto fotourl={props.imagen}></Foto>
        <Texto>
          <h2>{props.titulo}</h2>

          <p>{props.content}</p>
        </Texto>
        <br></br>
        <Texto>
          {props.biograph && (
            <Longbio>{BoldContentful(props.biograph)}</Longbio>
          )}
        </Texto>

        <Texto>{<h2>Contacto</h2>}</Texto>

        <Linklinkedin href={props.url} target="_blank">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="4" fill="#8A65C4" />
            <g clip-path="url(#clip0_801_4595)">
              <path
                d="M23.9968 24V23.9993H24.0008V18.1313C24.0008 15.2607 23.3828 13.0493 20.0268 13.0493C18.4135 13.0493 17.3308 13.9347 16.8888 14.774H16.8422V13.3173H13.6602V23.9993H16.9735V18.71C16.9735 17.3173 17.2375 15.9707 18.9622 15.9707C20.6615 15.9707 20.6868 17.56 20.6868 18.7993V24H23.9968Z"
                fill="white"
              />
              <path
                d="M8.26367 13.318H11.581V24H8.26367V13.318Z"
                fill="white"
              />
              <path
                d="M9.92133 8C8.86067 8 8 8.86067 8 9.92133C8 10.982 8.86067 11.8607 9.92133 11.8607C10.982 11.8607 11.8427 10.982 11.8427 9.92133C11.842 8.86067 10.9813 8 9.92133 8V8Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_801_4595">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(8 8)"
                />
              </clipPath>
            </defs>
          </svg>
        </Linklinkedin>
        {/*<ButtonStyled onClick={() => {
        console.log("click2");
        props.setAutorselected(props.titulo);
    
    }}>
        {" "}
        <Buttonarrow ></Buttonarrow>
      </ButtonStyled>*/}
      </Cardcontainer>
    </div>
  );
};

const Card = (props) => {
  return (
    <Cardcontainer>
      <Foto fotourl={props.imagen}></Foto>
      <Textoauth>
        <h2>{props.titulo}</h2>

        {props.content && <p>{props.content}</p>}
      </Textoauth>
      <ButtonStyled
        onClick={() => {
          
          props.setAutorselected(props.titulo);
        }}
      >
        {" "}
        <Buttonarrow></Buttonarrow>
      </ButtonStyled>
    </Cardcontainer>
  );
};

const Cardmedio = (props) => {
  return (
    <Cardcontainercol>
      <Fotocol fotourl={props.imagen}></Fotocol>
      <Texto>
        <h2>{props.titulo}</h2>
        <Row></Row>
        <p>{props.content}</p>
      </Texto>
      <ButtonStyledmed target="_blank" href={props.url}>
        {" "}
        <Buttonarrow></Buttonarrow>
      </ButtonStyledmed>
    </Cardcontainercol>
  );
};

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ImpactoEntry = "2oAZfLN7xDHsGeWDVtd6pk";
const EquipoEntry = "3pKSKXG6toPNXFEYGTX3L6";

const AutorPage = ({ location }, props) => {
  const { data: impacto, fetched } = useContentful({ id: ImpactoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });
  const { data: equipo, fetched: fetched3 } = useContentful({
    id: EquipoEntry,
  });

  const [autores, setAutores] = useState();
  const [equipoinfo, setEquipoinfo] = useState();
  const [autorselected, setAutorselected] = useState();
  const [showarticle, setShowarticle] = useState(false);
  const [impactofilter, setImpactofilter] = useState();
  const [mediofilter, setMediofilter] = useState();

  const [autorfilter, setAutorfilter] = useState();

  let author_id = decodeURI(location.pathname.split("/")[3]).replaceAll("-", " ");

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item.value}
        </span>
      </>
    );
  };

  /*useEffect(() => {
    const arraytitle = [];


    let arrayauthors = [];

    const authorpage = impacto?.fields.columnas.filter((item) => item.fields.pageWithAuthor);


    const author_ = authorpage?.map(item => (item.fields.authorName));
    const author =  [...new Set(author_)];
    setAutores(author);



  }, [impacto]);*/

  const processedAuthors = () => {
    const authorpage = impacto?.fields.columnas.filter(
      (item) => item.fields.pageWithAuthor
    );
    const authormedios = impacto?.fields.medios.filter(
      (item) => !!item.fields.author
    );

    const authorcol_ = authorpage?.map((item) => item.fields.authorName);
    const authorcol = [...new Set(authorcol_)];

    const authormed_ = authormedios?.map((item) => item.fields.author);
    const authormed = [...new Set(authormed_)];

    let author = authorcol.concat(authormed);

    return author;
  };

  useEffect(() => {
    if (impacto && equipo) {
      //const autorselected = author_id;
      setAutorselected(author_id);
     
      let impactofiltered_ = impacto.fields.columnas.filter(
        (item) => item.fields.authorName
      );

     
      let impactofiltered = impactofiltered_.filter(
        (item) => item.fields.authorName?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()  == author_id
      );

    
    
      let mediofiltered = impacto.fields.medios.filter(
        (item) => item.fields.author?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() === author_id
      );
      

      let autorchoosen = equipo.fields.equipos.filter(
        (item) => item.fields.nombre?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() == author_id
      );

      setImpactofilter(impactofiltered);
      setMediofilter(mediofiltered);
      setAutorfilter(autorchoosen[0]);
      setShowarticle(true);
    }
  }, [impacto, equipo]);

  useEffect(() => {
    const execute = async () => {
      const author = await processedAuthors();

      setAutores(author);
    };
    execute();
  }, [impacto]);

  /*useEffect(()=> {


if (equipo) {
(async () => {

  const equipodata = equipo.fields.equipos.filter(item => autores.includes(item.fields.nombre));

  setEquipoinfo(equipodata);
})();

}

},[equipo])*/

  /*const processedAuthorsdata = () => {
  console.log("start function",equipo);

  const equipodata = equipo?.fields.equipos.filter(item => autores.includes(item.fields.nombre));

 
  console.log("equipo data",equipodata);
    return equipodata;
}


  useEffect(() => {
   
const execute = async () => {
  console.log("start equipo",equipo);
  const data = await processedAuthorsdata();
  console.log("data",data);
  setEquipoinfo(data);
}
   
equipo && execute();


  },[equipo])*/

  useEffect(() => {
    (async function () {
      if (equipo) {
        const results = await filter(equipo.fields.equipos, async (num) => {
          await doAsyncStuff();
          return autores.includes(num.fields.nombre);
        });

        setTimeout(() => {
          setEquipoinfo(results);
        }, 500);
        //setEquipoinfo(results);
      }
    })();
  }, [equipo]);

 /* useEffect(() => {
    if (equipoinfo) {
      if (equipoinfo.length === 0) {
        refreshPage();
      }
    }
  }, [equipoinfo]);*/

  function refreshPage() {
    window.location.reload(false);
  }

  async function filter(arr, callback) {
    const fail = Symbol();
    return (
      await Promise.all(
        arr.map(async (item) => ((await callback(item)) ? item : fail))
      )
    ).filter((i) => i !== fail);
  }

  function doAsyncStuff() {
    return Promise.resolve();
  }

  return (
    <>
      {fetched && fetched2 && fetched3 && equipoinfo ? (
        <>
          <MetaTags>
            <title> {impacto.fields.pageTitle[4]} </title>
            <meta
              name={impacto.fields.metaDescription[4]}
              content={impacto.fields.metaContent[4]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Impacto",
                    item: "https://www.aporta.org.pe/impacto/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: "Columnas",
                    item: "https://www.aporta.org.pe/columnas/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={impacto.fields.headerImagenDeFondo.fields.file.url}
              ></Hero>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/impacto")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/impacto"
                  className={
                    location.pathname.startsWith("/impacto")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Impacto
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/impacto/autores"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Autores
                </Link>
              </Breadcrumbs>
              <Navegation selection={"5"}></Navegation>
              <br></br>
              <br></br>
              {!showarticle && (
                <Cardcontent>
                  {equipoinfo?.map((field, index) => {
                    return (
                      <Card
                        content={field.fields.biografiaShort}
                        imagen={field.fields.foto.fields.file.url}
                        titulo={field.fields.nombre}
                        url={field.fields.linkedinUrl}
                        setAutorselected={setAutorselected}
                      ></Card>
                    );
                  })}
                </Cardcontent>
              )}

              {showarticle && impactofilter && autorfilter && (
                <Authorcontent>
                  <Cardfilter
                    content={autorfilter.fields.puesto}
                    imagen={autorfilter.fields.foto.fields.file.url}
                    titulo={autorfilter.fields.nombre}
                    url={autorfilter.fields.linkedinUrl}
                    setAutorselected={setAutorselected}
                    biograph={autorfilter.fields.biografiaLong}
                    regresar={() => {
                      setShowarticle(false);
                      setAutorselected(undefined);
                    }}
                  ></Cardfilter>
                  <Articlesdiv>
                    <div
                      style={{
                        color: "var(--morado)",
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "16px",
                        marginLeft: "16px",
                      }}
                    >
                      Artículos
                    </div>
                    <Cardcontentcol>
                      {impactofilter?.map((field, index) => {
                        return (
                          <Cardarticle
                            className={"head"}
                            content={field.fields.cardDescription}
                            imagen={field.fields.cardImage.fields.file.url}
                            titulo={field.fields.titulo}
                            url={field.fields.slug}
                          ></Cardarticle>
                        );
                      })}

                      {mediofilter?.map((field, index) => {
                        return (
                          <Cardmedio
                            content={field.fields.descripcion}
                            imagen={field.fields.image.fields.file.url}
                            titulo={field.fields.titulo}
                            url={field.fields.url}
                          ></Cardmedio>
                        );
                      })}
                    </Cardcontentcol>
                  </Articlesdiv>
                </Authorcontent>
              )}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default AutorPage;
